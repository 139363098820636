import React, { useCallback,useRef,useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Store } from 'redux'
import { selectBotToken, useAppSelector } from 'src/redux/hooks'
import { useWidgetUiContext } from '../WidgetUiContext'
import { ChatInfo } from '../../interfaces'

interface SendBoxClickToBeginProps {
	notificationLabel: string
	store: Store
	pgCtx: any
	userId: string
	enableShorcut:boolean
	setClickToBegin: (b: boolean) => void
	setHideSendBox: (b: boolean) => void
}
function SendBoxClickToBegin({
	notificationLabel,
	store,
	pgCtx,
	userId,
	enableShorcut,
	setClickToBegin,
	setHideSendBox,
}: SendBoxClickToBeginProps) {
	const { t } = useTranslation()
	const { conversationId, ls } = useWidgetUiContext()
	const botToken = useAppSelector(selectBotToken)
	const [refernaceState,setReferenceState] = useState(0) 

	const handleClickToBegin = useCallback(() => {
		setClickToBegin(false)
		setHideSendBox(false)

		if (store) {
			store.dispatch({
				type: 'WEB_CHAT/SEND_MESSAGE',
				payload: { text: t('Hi') },
			})
			const storeConversation = {
				chatConversationId: conversationId,
				timestamp: Date.now(), // moment .utc(),
				pgCtx,
				userId,
				token: botToken,
			}

			// No record of chatConversationId means new convo
			const chatInfo = ls.get(pgCtx || 'chatInfo') as ChatInfo
			const isNewConvo = !chatInfo?.chatConversationId

			isNewConvo && (pgCtx ? ls.set(pgCtx, storeConversation) : ls.set(pgCtx || 'chatInfo', storeConversation))
			
			//  widget2-3-0
			// !conversationId && (pgCtx ? ls.set(pgCtx, storeConversation) : ls.set('chatInfo', storeConversation))
			
			localStorage.removeItem('_secure__ls__metadata')
		}
	}, [setClickToBegin, setHideSendBox, conversationId])

	const buttonRef = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
		  if (event.key === 'Enter') {
			event.preventDefault()
			buttonRef.current?.click();
		  }
		};
	   
		const handleEnter = (event: KeyboardEvent) => {
		  if (event.key === 'Enter' || (event.ctrlKey && event.key.toLowerCase() === 'e')) {
			  setReferenceState((prevCount) => prevCount + 1);
		  }
		}
		// setTimeout(()=>{
		  let findIsClosed:any = document.querySelector('div.close-bot') || null 
		  if(!findIsClosed){
			  setReferenceState(1)
		  }
		  if(!findIsClosed && refernaceState===1){
				  window.addEventListener('keydown', handleKeyDown);
		  }
		// },800)
	  
		const handleClick = () => {
		  const element = document.querySelector('.chat-container');
		  if (element) {
			setReferenceState((prevCount) => prevCount + 1);
		  } 
		};
	
		document.addEventListener('click', handleClick);
		window.addEventListener('keydown', handleEnter);
  
		return () => {
		  window.removeEventListener('keydown', handleKeyDown);
		  window.removeEventListener('keydown', handleEnter);
		  document.removeEventListener('click', handleClick);
		};
	  }, [refernaceState]);

	return (
		<div className="click-begin" {...(enableShorcut? { 'aria-label': t('(Enter key)') } : {})}  data-microtip-position="top" role="tooltip" style={{marginTop:"-20px"}}>	
			<span ref={enableShorcut ? buttonRef :null} onClick={handleClickToBegin}>
				{notificationLabel ? t(notificationLabel) : t('Click To Begin')}
			</span>
		</div>
	)
}
export default SendBoxClickToBegin
